<template>
  <a-spin :spinning="spinning">
    <div class="warpper">
      <a-card>
        <a-row class="article">
          <a-col :span="19">
            <div class="header-title-text"><strong>月度报表数据</strong></div>
            <div>
              <p class="header-title-item">
                <strong><span>单位：元</span></strong
                ><strong><span>收款</span></strong>
              </p>
            </div>
          </a-col>
          <a-col class="article" :span="3">
            <!-- <a-form-model-item class="search-area" label="大区事务所">
            <a-cascader
              style="width:200px"
              :options="dealerList"
              v-model="regionalOffice"
              change-on-select
              placeholder="请选择大区/事务所"
              @change="onChange"
              :field-names="{ label: 'title', value: 'id', children: 'children' }"
            />
          </a-form-model-item> -->
            <a-month-picker :placeholder="`${this.year.format('YYYY-MM')}`" :disabledDate="disabledDate" @change="onChangeTime" />
          </a-col>
          <a-col style="margin: 12px 0 0 2%" :span="4">
            <downLoad
              method="post"
              api="/api/base/system/report/excelReportFirmCatePayment"
              :params="searchDataObj"
              @downLoadDone="downLoadDone"
              @startDownLoad="startDownLoad"
              :isConfirmLoading="tableLoading"
              name="事务所按分类收款报表.xls"
              >导出</downLoad
            >
          </a-col>
        </a-row>
        <div>
          <a-table
            :scroll="tableSize"
            :loading="tableLoading"
            :columns="table_config.columns"
            :dataSource="table_config.tableDataMonth"
            :row-class-name="rowClassName"
            :rowKey="(record,index)=>{return index}"
            :pagination="false"
            bordered
          >
            <template slot="SJ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
          </a-table>
          <a-row>
            <a-col :span="16">
              <div class="header-title-text"><strong>累计报表数据</strong></div>
              <div>
                <p class="header-title-item">
                  <strong><span>单位：元</span></strong
                  ><strong><span>收款</span></strong>
                </p>
              </div>
            </a-col>
          </a-row>
          <a-table
            :scroll="tableSize"
            :loading="tableLoading"
            :columns="table_config.cumulative_columns"
            :dataSource="table_config.tableDataCumulative"
            :row-class-name="rowClassName"
            :rowKey="(record,index)=>{return index}"
            :pagination="false"
            bordered
          >
            <template slot="MSJ"><span v-show="isShow">1~</span>{{ MSJ }}月实际</template>
            <template slot="MTQ"><span v-show="isShow">1~</span>{{ MTQ }}月同期</template>
            <template slot="MTQB"><span v-show="isShow">1~</span>{{ MTQB }}月同期比</template>
            <template slot="SJ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
          </a-table>
          <br />
          <a-row>
            <a-col :span="21">
              <div class="header-title-text">
                <strong>{{ title }}</strong>
              </div>
              <div>
                <p class="header-title-item">
                  <strong><span>单位：元</span></strong
                  ><strong><span>收款</span></strong>
                </p>
              </div>
            </a-col>
            <a-col :span="2">
              <div>
                <a-select default-value="Q1一季度报表数据" style="width: 200px" @change="handleChange">
                  <a-select-option value="3"> Q1一季度报表数据 </a-select-option>
                  <a-select-option value="4"> Q2二季度报表数据 </a-select-option>
                  <a-select-option value="5"> Q3三季度报表数据 </a-select-option>
                  <a-select-option value="6"> Q4四季度报表数据 </a-select-option>
                </a-select>
              </div>
            </a-col>
          </a-row>
          <a-table
            :scroll="tableSize"
            :loading="tableLoading"
            :columns="table_config.quarter_columns"
            :dataSource="table_config.tableData"
            :row-class-name="rowClassName"
            :rowKey="(record,index)=>{return index}"
            :pagination="false"
            bordered
          >
            <template slot="SJ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="Q"
              ><span>{{ Q }}目标</span></template
            >
            <template slot="QSJ"
              ><span>{{ Q }}实际</span></template
            >
            <template slot="QVS"
              ><span>{{ Q }}完成率实际VS考核目标</span></template
            >
            <template slot="QTQ"
              ><span>{{ Q }}同期</span></template
            >
            <template slot="QB"
              ><span>{{ Q }}同期比</span></template
            >
          </a-table>
        </div>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
import ReportTableData from './components/ReportTableData.vue'
import moment from 'moment'
import { formatSale } from './components/colums.js'
export default {
  name: 'report事务所报表',
  components: { ReportTableData, moment },
  data() {
    return {
      MLength: null,
      NLength: null,
      QLength: null,
      // Q2Disabled: false,
      // Q3Disabled: false,
      // Q4Disabled: false,
      tableSize: { x: 1500, y: 560 },
      title: 'Q1一季度报表数据',
      text: '',
      open: false,
      isShow: true,
      tableLoading: false,
      spinning: false,
      year: moment(),
      month: moment().month() + 1,
      searchSaleExcel: {
        date: moment().format('YYYY-MM'),
      },
      searchData: {
        date: moment().format('YYYY-MM'),
        type: '3',
      },
      searchMonth: {
        date: moment().format('YYYY-MM'),
        type: '1',
      },
      searchCumulative: {
        date: moment().format('YYYY-MM'),
        type: '2',
      },
      Q: '',
      MSJ: moment().month() + 1,
      MTQ: moment().month() + 1,
      MTQB: moment().month() + 1,
      regionalOffice: [],
      dealerList: [],
      table_config: {
        columns: [],
        cumulative_columns: [],
        quarter_columns: [],
        tableDataMonth: [],
        tableDataCumulative: [],
        tableData: [],
      },
    }
  },
  created() {
    this.getChoiceDataMonth()
    this.getChoiceDataCumulative()
    this.getChoiceData()
    // this.hideSelect()
    this.getProCateNameList()
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      // 获取大区事务所经销商数据
      this.dealerList = res.body
    })
  },
  mounted() {},
  computed: {
    searchDataObj() {
      const obj = {
        ...this.searchSaleExcel,
      }
      return obj
    },
  },
  methods: {
    moment,
    // hideSelect() {
    //   if (this.month <= 3) {
    //     this.Q2Disabled = true
    //     this.Q3Disabled = true
    //     this.Q4Disabled = true
    //   } else if (this.month <= 6) {
    //     this.Q2Disabled = false
    //     this.Q3Disabled = true
    //     this.Q4Disabled = true
    //   } else if (this.month <= 9) {
    //     this.Q2Disabled = false
    //     this.Q4Disabled = true
    //     this.Q3Disabled = false
    //   } else {
    //     this.Q2Disabled = false
    //     this.Q3Disabled = false
    //     this.Q4Disabled = false
    //   }
    // },
    downLoadDone() {
      this.spinning = false
    },
    startDownLoad() {
      this.spinning = true
    },
    disabledDate(current) {
      // 禁用时间选择区间
      // return current && current < moment().endOf('day');
      return current && current > Date.now()
    },
    onChangeTime(date) {
      if(!date){
        date = moment();
      }
      this.year = date
      this.open = false
      this.searchSaleExcel.date = date.format('YYYY-MM')
      this.searchData.date = date.format('YYYY-MM')
      this.searchMonth.date = date.format('YYYY-MM')
      this.searchCumulative.date = date.format('YYYY-MM')
      ;(this.month = date.month() + 1), (this.text = date.format('YYYY-MM'))
      this.MSJ = date.month() + 1
      this.MTQ = date.month() + 1
      this.MTQB = date.month() + 1
      const titleSection = date.month() + 1
      if (titleSection == 1) {
        this.isShow = false
      } else {
        this.isShow = true
      }
      // this.hideSelect()
      this.getChoiceDataMonth()
      this.getChoiceDataCumulative()
      this.getChoiceData()
    },
    handleChange(value) {
      this.searchData.type = value
      this.getChoiceData()
    },
    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
    getChoiceDataMonth() {
      this.getProCateNameList()
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportFirmCatePayment', this.searchMonth)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.tableDataMonth = res.body
            this.MLength = formatSale(res.body)
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    getChoiceDataCumulative() {
      this.getProCateNameList()
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportFirmCatePayment', this.searchCumulative)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.tableDataCumulative = res.body
            this.NLength = formatSale(res.body)
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },

    getChoiceData() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportFirmCatePayment', this.searchData)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.tableData = res.body
            this.QLength = formatSale(res.body)
            switch (this.searchData.type) {
              case '3':
                this.title = 'Q1一季度报表数据'
                this.Q = 'Q1'
                break
              case '4':
                this.title = 'Q2二季度报表数据'
                this.Q = 'Q2'
                break
              case '5':
                this.title = 'Q3三季度报表数据'
                this.Q = 'Q3'
                break
              case '6':
                this.title = 'Q4四季度报表数据'
                this.Q = 'Q4'
                break
            }
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    getProCateNameList() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getProCateNameList', {})
        .then((res) => {
          this.tableLoading = false
          const res_columns = []
          const cumulative_res_columns = []
          const quarter_res_columns = []
          const init_columns = [
            {
              title: '事务所',
              align: 'center',
              dataIndex: 'firmName',
              key: 1,
              width: 150,
              className: 'headerOrange',
              customCell: this.MonthBackground,
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.MLength) {
                  obj.attrs.colSpan = 2
                }
                return obj
              },
              fixed: 'left',
            },
            {
              title: '大区',
              dataIndex: 'areaName',
              align: 'center',
              key: 446,
              width: 150,
              className: 'headerOrange',
              customCell: this.MonthBackground,
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.MLength) {
                  obj.attrs.colSpan = 0
                }
                return obj
              },

              fixed: 'left',
            },
          ]
          const n_columns = [
            {
              title: '事务所',
              align: 'center',
              dataIndex: 'firmName',
              key: 1,
              width: 150,
              className: 'headerOrange',
              customCell: this.NBackground,
              fixed: 'left',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.NLength) {
                  obj.attrs.colSpan = 2
                }
                return obj
              },
            },
            {
              title: '大区',
              dataIndex: 'areaName',
              align: 'center',
              key: 446,
              width: 150,
              className: 'headerOrange',
              customCell: this.NBackground,
              fixed: 'left',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.NLength) {
                  obj.attrs.colSpan = 0
                }
                return obj
              },
            },
          ]
          const q_columns = [
            {
              title: '事务所',
              align: 'center',
              dataIndex: 'firmName',
              key: 1,
              width: 150,
              className: 'headerOrange',
              customCell: this.QBackground,
              fixed: 'left',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.QLength) {
                  obj.attrs.colSpan = 2
                }
                return obj
              },
            },
            {
              title: '大区',
              dataIndex: 'areaName',
              align: 'center',
              key: 446,
              width: 150,
              className: 'headerOrange',
              customCell: this.QBackground,
              fixed: 'left',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                }
                if (index === this.QLength) {
                  obj.attrs.colSpan = 0
                }
                return obj
              },
            },
          ]
          res.body.forEach((item) => {
            res_columns.push({
              title: item.name,
              children: [
                {
                  title: this.month + '月实际',
                  dataIndex: item.id + 'SJ',
                  key: item.id + 'SJ',
                  width: 120,
                  align: 'center',
                  className: 'headerOrange',
                  customCell: this.fontText,
                  scopedSlots: { customRender: 'SJ' },
                },
                {
                  title: this.month + '月同期',
                  dataIndex: item.id + 'TQ',
                  key: item.id + 'TQ',
                  width: 120,
                  align: 'center',
                  className: 'LightBlue',
                  customCell: this.MBlueBackground,
                  scopedSlots: { customRender: 'TQ' },
                },
                {
                  title: this.month + '月同期比',
                  dataIndex: item.id + 'TQB',
                  key: item.id + 'TQB',
                  width: 120,
                  align: 'center',
                  className: 'headerGreen',
                  customCell: this.MGreenBackground,
                },
              ],
            })
          })
          res_columns.push({
            title: '全部商品',
            children: [
              {
                title: this.month + '月实际',
                dataIndex: 'SJ',
                key: 'SJ',
                width: 120,
                align: 'center',
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'SJ' },
              },
              {
                title: this.month + '月同期',
                dataIndex: 'TQ',
                key: 'TQ',
                width: 120,
                align: 'center',
                className: 'LightBlue',
                customCell: this.MBlueBackground,
                scopedSlots: { customRender: 'TQ' },
              },
              {
                title: this.month + '月同期比',
                dataIndex: 'TQB',
                key: 'TQB',
                width: 120,
                align: 'center',
                className: 'headerGreen',
                customCell: this.MGreenBackground,
              },
            ],
          })
          res.body.forEach((item) => {
            cumulative_res_columns.push({
              title: item.name,
              children: [
                {
                  // title: '1~' + this.month + '月实际',
                  slots: {
                    title: 'MSJ',
                  },
                  dataIndex: item.id + 'SJ',
                  key: item.id + 'SJ',
                  width: 120,
                  align: 'center',
                  className: 'headerOrange',
                  customCell: this.fontText,
                  scopedSlots: { customRender: 'SJ' },
                },
                {
                  // title: '1~' + this.month + '月同期',
                  slots: {
                    title: 'MTQ',
                  },
                  dataIndex: item.id + 'TQ',
                  key: item.id + 'TQ',
                  width: 120,
                  align: 'center',
                  className: 'LightBlue',
                  customCell: this.NBlueBackground,
                  scopedSlots: { customRender: 'TQ' },
                },
                {
                  // title: '1~' + this.month + '月同期比',
                  slots: {
                    title: 'MTQB',
                  },
                  dataIndex: item.id + 'TQB',
                  key: item.id + 'TQB',
                  width: 110,
                  align: 'center',
                  className: 'headerGreen',
                  customCell: this.NGreenBackground,
                },
              ],
            })
          })
          cumulative_res_columns.push({
            title: '全部商品',
            children: [
              {
                // title: '1~' + this.month + '月实际',
                slots: {
                  title: 'MSJ',
                },
                dataIndex: 'SJ',
                key: 'SJ',
                width: 120,
                align: 'center',
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'SJ' },
              },
              {
                // title: '1~' + this.month + '月同期',
                slots: {
                  title: 'MTQ',
                },
                dataIndex: 'TQ',
                key: 'TQ',
                width: 120,
                align: 'center',
                className: 'LightBlue',
                customCell: this.NBlueBackground,
                scopedSlots: { customRender: 'TQ' },
              },
              {
                // title: '1~' + this.month + '月同期比',
                slots: {
                  title: 'MTQB',
                },
                dataIndex: 'TQB',
                key: 'TQB',
                width: 110,
                align: 'center',
                className: 'headerGreen',
                customCell: this.NGreenBackground,
              },
            ],
          })
          res.body.forEach((item) => {
            quarter_res_columns.push({
              title: item.name,
              children: [
                {
                  slots: {
                    title: 'QSJ',
                  },
                  dataIndex: item.id + 'SJ',
                  key: item.id + 'SJ',
                  width: 120,
                  align: 'center',
                  className: 'headerOrange',
                  customCell: this.fontText,
                  scopedSlots: { customRender: 'SJ' },
                },
                {
                  slots: {
                    title: 'QTQ',
                  },
                  dataIndex: item.id + 'TQ',
                  key: item.id + 'TQ',
                  width: 120,
                  align: 'center',
                  className: 'LightBlue',
                  customCell: this.QBlueBackground,
                  scopedSlots: { customRender: 'TQ' },
                },
                {
                  slots: {
                    title: 'QB',
                  },
                  dataIndex: item.id + 'TQB',
                  key: item.id + 'TQB',
                  width: 110,
                  align: 'center',
                  className: 'headerGreen',
                  customCell: this.QGreenBackground,
                },
              ],
            })
          })
          quarter_res_columns.push({
            title: '全部商品',
            children: [
              {
                slots: {
                  title: 'QSJ',
                },
                dataIndex: 'SJ',
                key: 'SJ',
                width: 120,
                align: 'center',
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'SJ' },
              },
              {
                slots: {
                  title: 'Q',
                },
                dataIndex: 'TQ',
                key: 'TQ',
                width: 120,
                align: 'center',
                className: 'LightBlue',
                customCell: this.QBlueBackground,
                scopedSlots: { customRender: 'TQ' },
              },
              {
                slots: {
                  title: 'QB',
                },
                dataIndex: 'TQB',
                key: 'TQB',
                width: 110,
                align: 'center',
                className: 'headerGreen',
                customCell: this.QGreenBackground,
              },
            ],
          })
          this.table_config.columns = init_columns.concat(res_columns)
          this.table_config.cumulative_columns = n_columns.concat(cumulative_res_columns)
          this.table_config.quarter_columns = q_columns.concat(quarter_res_columns)
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    MonthBackground(record, index) {
      if (index >= 0 && index < this.MLength) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    MGreenBackground(record, index) {
      if (index >= 0 && index < this.MLength) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    MBlueBackground(record, index) {
      if (index >= 0 && index < this.MLength) {
        return {
          style: {
            'background-color': '#ddebf7',
            'text-align': 'right',
          },
        }
      } else {
        return {
          style: {
            'text-align': 'right',
          },
        }
      }
    },
    NBackground(record, index) {
      if (index >= 0 && index < this.NLength) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    NGreenBackground(record, index) {
      if (index >= 0 && index < this.NLength) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    NBlueBackground(record, index) {
      if (index >= 0 && index < this.NLength) {
        return {
          style: {
            'background-color': '#ddebf7',
            'text-align': 'right',
          },
        }
      } else {
        return {
          style: {
            'text-align': 'right',
          },
        }
      }
    },
    QBackground(record, index) {
      if (index >= 0 && index < this.QLength) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    QGreenBackground(record, index) {
      if (index >= 0 && index < this.QLength) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    QBlueBackground(record, index) {
      if (index >= 0 && index < this.QLength) {
        return {
          style: {
            'background-color': '#ddebf7',
            'text-align': 'right',
          },
        }
      } else {
        return {
          style: {
            'text-align': 'right',
          },
        }
      }
    },
    fontText() {
      return {
        style: {
          'text-align': 'right',
        },
      }
    },
    rowClassName(record) {
      const className = 'light-row'
      const classNum = 'light-row'
      const classUN = 'light-row'
      const classUNum = 'light-row'
      if (record.februaryAim === '小计') {
        return {
          className,
        }
      } else if (record.februaryAim === '总计') {
        return {
          classNum,
        }
      } else if (
        record.areaName === '合计' ||
        record.channel === '合计' ||
        record.dept === '合计' ||
        record.firmName === '合计'
      ) {
        return {
          classUN,
        }
      } else if (record.categoryName === '小计' || record.categoryName === '三菱产品小计') {
        return {
          classUNum,
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.year {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.article {
  display: flex;
  align-items: center;
}
.search-area {
  display: flex;
  align-items: center;
  width: 300px;
  margin-top: 25px;
}
.header-title-item {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.header-title-text {
  font-size: 20px;
}
::v-deep .ant-table-thead > tr > th.headerPink {
  background-color: #fce4d6;
}
::v-deep .ant-table-thead > tr > th.headerGreen {
  background-color: #e2efda;
}
::v-deep .ant-table-thead > tr > th.headerOrange {
  background-color: #ffe699;
}
::v-deep .ant-table-thead > tr > th.leftHeader {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.LightBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-tbody > .classOneTitle {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .className {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classNum {
  background-color: #ffc000;
}
::v-deep .ant-table-tbody > .classUN {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classUNum {
  background-color: #d9d9d9;
}
</style>